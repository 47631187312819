import { firstValueFrom, map, timer } from 'rxjs';

export class PromiseUtils {
    public static async run<T>(task: () => Promise<T>, suppressError = false) {
        try {
            return await task();
        } catch (e) {
            if (!suppressError) {
                throw e;
            }
        }
    }

    public static async delay(milliseconds: number): Promise<null> {
        return firstValueFrom(timer(milliseconds).pipe(map(i => null)));
    }
}
